import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import Navigation from "./nav";
import Footer from "./footer";
import serviceSecOneImg from "../assets/img/serviceSecOneImg.svg";
import logoEmb from "../assets/img/logoEmb.png";
import serviceOne from "../assets/img/manufactureOne.jpg";
import serviceTwo from "../assets/img/manufactureTwo.jpg";
import serviceThree from "../assets/img/manufactureThree.jpg";
import serviceFour from "../assets/img/manufactureFour.jpg";
import serviceFive from "../assets/img/manufactureFive.jpg";
import serviceSix from "../assets/img/manufactureSix.jpg";
import serviceSeven from "../assets/img/manufactureSeven.jpg";
import serviceEight from "../assets/img/manufactureEight.jpg";
import serviceNine from "../assets/img/manufactureNine.jpg";
import serviceTen from "../assets/img/manufactureTen.jpg";
import serviceEleven from "../assets/img/manufactureEleven.jpg";
import serviceTwelve from "../assets/img/manufactureTwelve.jpg";
import manufacturingGuide from "../assets/pdf/OutsourcingElectronicsManufacture.pdf";
import { Link } from "react-router-dom";
const ElectronicsManufacturing = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // when page this page is call from another page its working        
    }, []);
    return (
        <>
            {/* navbar - start */}
            <Navigation />
            {/* navbar - end */}
            {/* hero section - start */}
            <div className="container-fluid serviceSec d-flex justify-content-center align-items-center">
                {/* <img className="serviceSecOneImg" src={serviceSecOneImg} alt='corechip' /> */}
                <div className="leftSideBg"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-12 m-auto text-justify">
                            <h1 className="titleThree fw-600 black-232323">Corechip Manufacture</h1>
                            <p className="descriptionTwo fw-400 black-272727 mb-0">Corechip benefits from electronics manufacturing facility in India enables us to offer small batch or high volume production as well as full test, QA and supply chain solutions.</p>
                        </div>
                        <div className="col-md-6 col-12 m-auto">
                            <div className="right">
                                <img className="aboutUsImg" src={logoEmb} alt='corechip' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* hero section - end */}
            {/* designSecTwo section - start */}
            <div className="container-fluid designSecTwo py-40">
                <div className="container">
                    <div className="row row-gap">
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/manufacture/v-pills-link-m1">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceOne} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Component Sourcing</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/manufacture/v-pills-link-m2">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceTwo} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">PCB Assembly</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/manufacture/v-pills-link-m3">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceThree} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Box Build</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/manufacture/v-pills-link-m4">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceFour} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Flexible Supply</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/manufacture/v-pills-link-m5">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceFive} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Wire Bonding</h1>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/manufacture/v-pills-link-m6">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceSix} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Thick Flim Hybrids</h1>
                                </div>
                            </Link>
                        </div> */}
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/manufacture/v-pills-link-m7">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceSeven} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Laser Trimming</h1>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/manufacture/v-pills-link-m8">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceEight} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Coating and Encapsulation</h1>
                                </div>
                            </Link>
                        </div> */}
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/manufacture/v-pills-link-m9">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceNine} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Production Engineering</h1>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/manufacture/v-pills-link-m10">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceTen} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Quality Accreditations</h1>
                                </div>
                            </Link>
                        </div> */}
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/manufacture/v-pills-link-m11">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceEleven} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Indian Electronics Assembly</h1>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/manufacture/v-pills-link-m12">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceTwelve} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Far East Manufacturing</h1>
                                </div>
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* designSecTwo section - end */}
            {/* designSecThree Section - start */}
            <div className="container-fluid designSecThree bgColor-F2F2F2 py-40">
                <div className="container text-justify">
                    <h1 className="titleThree fw-500 black">Electronics Manufacturing</h1>
                    <h2 className="titleFour fw-600 black">Why Choose Corechip?</h2>
                    <p className="descriptionTwo fw-400 black">Using a skilled and reliable Contract Electronics Manufacturer (CEM) can provide the following benefits:</p>
                    <ul>
                        <li className="descriptionTwo fw-400 black">Increased technical resource, equipment, staff knowledge and experience.</li>
                        <li className="descriptionTwo fw-400 black">Improved quality controls.</li>
                        <li className="descriptionTwo fw-400 black">Improved cost control.</li>
                        <li className="descriptionTwo fw-400 black">Reliable components from trusted and established procurement channels.</li>
                        <li className="descriptionTwo fw-400 black">Dedicated customer support and logistical management to simplify your supply chain.</li>
                        <li className="descriptionTwo fw-400 black">Flexible storage and supply options (globally).</li>
                    </ul>
                    <p className="descriptionTwo fw-400 black">With over five years' experience supplying a variety of high-tech industries, we believe that Corechip is perfectly placed to deliver these benefits, and more, for your business.</p>
                    {/* <a href={manufacturingGuide} target='_blank' rel="noopener noreferrer"><input type="button" className="btn text-uppercase bgPrimaryColor fw-600 white br-20" value="Outsourcing Electronics Manufacturing Guide" /></a> */}
                    <p className="descriptionTwo fw-400 black mt-10">We aim to tailor our service for every customer's unique requirements, contact our team to discuss yours requirement  today !</p>
                </div>
            </div>
            {/* designSecThree Section - end */}
            {/* footer - start */}
            <Footer />
            {/* footer - end */}
        </>
    )
}
export default ElectronicsManufacturing;