import React, { useEffect } from 'react';
import Navigation from "./nav";
import Footer from "./footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import logoEmb from "../assets/img/logoEmb.png";
import stdProductOne from "../assets/img/stdProductOne.jpg";
import stdProductTwo from "../assets/img/stdProductTwo.jpg";
import stdProductThree from "../assets/img/stdProductThree.jpg";
import stdProductFour from "../assets/img/productSecOneImgOne.svg";
import { Link } from 'react-router-dom';

const StandardProducts = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/* navbar - start */}
            <Navigation />
            {/* navbar - end */}
            {/* hero section - start */}
            <div className='container-fluid d-flex justify-content-center align-items-center standardProductHeroSec px-0'>
                <div className="leftSideBg"></div>
                {/* <div className="rightSideBg"></div> */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-12 m-auto text-justify">
                            <h1 className="titleThree fw-600 black-232323">Corechip Products</h1>
                            <p className="descriptionTwo fw-400 black-272727 mb-0">Alongside our custom services, Corechip produces a range of standard products. we understand the importance of convenience and efficiency in your daily routine. That’s why we proudly present our cutting-edge Ceiling cloth hanger, designed to revolutionize the way you dry your clothes. Say goodbye to the hassle of traditional drying methods and embrace the future of laundry with our state-of-the-art appliance.</p>
                        </div>
                        <div className='col-md-6 col-12 m-auto'>
                            <div className="right">
                                <img className="" src={logoEmb} alt='corechip' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* hero section - end */}
            {/* standardproduct section - start */}
            <div className='container-fluid standardProductSecOne py-40 bgColor-F2F2F2'>
                <div className='container'>
                    <div className='row row-gap'>
                        <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                            <Link to="https://www.jusdry.com/" target='_blank'>
                                <div class="card br-none">
                                    <img className='stdProductImg' src={stdProductOne} alt="corechip" />
                                    <div class="blackOverlay"></div>
                                    <h4 className='titleFour mb-0 fw-600 text-center'>Wireless Data</h4>
                                </div>
                            </Link>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                            <Link to="https://www.jusdry.com/" target='_blank'>
                                <div class="card br-none">
                                    <img className='stdProductImg' src={stdProductTwo} alt="corechip" />
                                    <div class="blackOverlay"></div>
                                    <h4 className='titleFour mb-0 fw-600 text-center'>USB Data Loggers</h4>
                                </div>
                            </Link>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                            <Link to="https://www.jusdry.com/" target='_blank'>
                                <div class="card br-none">
                                    <img className='stdProductImg' src={stdProductThree} alt="corechip" />
                                    <div class="blackOverlay"></div>
                                    <h4 className='titleFour mb-0 fw-600 text-center'>Smart Displays</h4>
                                </div>
                            </Link>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                            <Link to="https://www.jusdry.com/" target='_blank'>
                                <div class="card br-none">
                                    <img className='stdProductImg' src={stdProductFour} alt="corechip" />
                                    <div class="blackOverlay"></div>
                                    <h4 className='titleFour mb-0 fw-600 text-center'>Smart Hanger</h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* standardproduct section - end */}
            {/*  */}
            {/* Footer - Start */}
            <Footer />
            {/* Footer - End */}
        </>
    )
}
export default StandardProducts;