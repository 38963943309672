import React, {useState, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import Navigation from "./nav";
import Footer from "./footer";
import closeBtn from '../assets/img/closeBtn.svg';
import { Link, useLocation, useNavigate } from "react-router-dom";


const Cart = () => {
    const storedData = localStorage.getItem("products");
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const name = searchParams.get('name');
    const price = searchParams.get('price');
    const size = searchParams.get('size');
    const color = searchParams.get('color');
    const quantity = searchParams.get('quantity');
    const discount = searchParams.get('discount');
    const imgurl = searchParams.get('imgUrl');

    const [products, setProducts] = useState([]);
    const [totalItems, setTotalItems] = useState();
    const [subtotal, setSubtotal] = useState();
    const [disPrice, setDisPrice] = useState();
    const [total, setTotal] = useState();

    useEffect(() => {
        if(name && price && size && color && quantity && imgurl && discount){
            const addToCart = () => {
                const data = {
                    id: ((products.length)?(products.length):((JSON.parse(storedData)).length)) + 1,
                    name: name,
                    color: color,
                    size: size,
                    price: Number(price),
                    discount: Number(discount),
                    quantity: Number(quantity),
                    image: imgurl,
                    subtotal: (price * quantity)
                };
            setProducts([...products,...(JSON.parse(storedData)), data]);
            }
            addToCart();
        }else{
            if(storedData){
                setProducts([...products,...(JSON.parse(storedData))]);
            }
        }
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            return (event.returnValue = '');
        };
        window.addEventListener('beforeunload', navigate('/cart') );
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }; 
    },[]);     
    
    useEffect(() => {
        localStorage.setItem("products", JSON.stringify(products));
        updateTotals(products);
    }, [products]);

    const updateTotals = (updatedProducts) => {
        if (updatedProducts.length === 0) {
            setTotalItems(0);
            setSubtotal(0);
            setTotal(0);
            setDisPrice(0);
            return;
        }
        let stotal=0;
        for (let i = 0; i < products.length; i++) {
            let dis =  products[i].quantity * (products[i].price -  products[i].discount);
            stotal += dis;
        }
        const totalItems = updatedProducts.reduce((total, product) => total + product.quantity, 0);
        const subtotal = updatedProducts.reduce((total, product) => total + product.subtotal, 0);
        const total = subtotal - stotal;
        
        setTotalItems(totalItems);
        setSubtotal(subtotal);
        setDisPrice(stotal);
        setTotal(total);
    };

    const handleQuantityChange = (productId, newQuantity) => {
        const updatedProducts = products.map((product) =>
            product.id === productId
                ? { ...product, quantity: newQuantity, subtotal: product.price * newQuantity }
                : product
        );
        setProducts(updatedProducts);
    };

    const handleRemoveProduct = (productId) => {
        const updatedProducts = products.filter((product) => product.id !== productId);
        setProducts(updatedProducts);
    };
    const isCartEmpty = products.length;
    return (
        <>
            {/* navbar-start */}
            <Navigation totalItems={totalItems} />
            {/* navbar-end */}
            {/* cartsection one - start */}
            <div className="container-fluid bgSecondaryColor cartSecOne d-flex justify-content-center align-items-center">
                <h1 className="titleOne fw-700 primaryColor text-uppercase">Cart</h1>
            </div>
            {/* cartsection one - end */}
            {/* cartsection two - start */}
            <div className="container-fluid py-50 cartSecTwo">
                <div className="container">
                    <div className="row row-gap">
                        <div className="col-lg-8 col-md-10 m-auto">
                            <div className="shopping-table">
                                {products.length > 0 && (
                                    <table className="text-center">
                                        <thead>
                                            <tr>
                                                <th className="descriptionThree fw-600 black">Image</th>
                                                <th className="descriptionThree fw-600 black">Name</th>
                                                <th className="descriptionThree fw-600 black">Price</th>
                                                <th className="descriptionThree fw-600 black">Quantity</th>
                                                <th className="descriptionThree fw-600 black">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {products.map((product) => (
                                                <tr key={product.id}>
                                                    <td>
                                                        <img src={product.image} alt={product.name} style={{ width: '50px' }} />
                                                    </td>
                                                    <td>
                                                        <p className="descriptionThree fw-600 black-232323 mb-0">{product.name}</p>
                                                        <p className="descriptionThree fw-400 black mb-0"><span className="color-828282">Color : </span>{product.color}</p>
                                                        <p className="descriptionThree fw-400 black mb-0"><span className="color-828282">Size : </span>{product.size}</p>
                                                    </td>
                                                    <td className="descriptionThree fw-500 black-232323">₹{ isNaN(product.price) ? '0' : product.price.toLocaleString()}</td>
                                                    <td>
                                                        <div className="quantityControl bgColor-F2F2F2 m-auto">
                                                            <button className="b-none bgColor-F2F2F2" onClick={() => handleQuantityChange(product.id, Math.max(1, product.quantity - 1))}>-</button>
                                                            <span className="bgColor-F2F2F2 mb-0 descriptionThree fw-500 black">{isNaN(product.quantity) ? '0' : product.quantity}</span>
                                                            <button className="b-none bgColor-F2F2F2" onClick={() => handleQuantityChange(product.id, product.quantity + 1)}>+</button>
                                                        </div>
                                                    </td>
                                                    <td className="descriptionThree fw-500 black-232323">₹{ isNaN(product.subtotal) ? '0' : product.subtotal.toLocaleString()}</td>
                                                    <td>
                                                        <img className="closeBtn" onClick={() => handleRemoveProduct(product.id)} src={closeBtn} alt='closeBtn' />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                                <Link to="/ourproducts"><button className="text-uppercase btn mt-25 primaryColor fw-500 br-4 description">{isCartEmpty ? "Shop Now" : "Shop More"}</button></Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-10 m-auto">
                            <div className="card p-15 br-4">
                                <h1 className="titleThree text-uppercase fw-500">Total Items<span className="primaryColor float-right">{isNaN(totalItems)?'0':totalItems}</span></h1>
                                <div className="py-2">
                                    <p className="fw-400 descriptionThree mb-8">Price: <span className="float-right fw-600">₹{isNaN(subtotal)?'0':subtotal}</span></p>
                                    <p className="fw-400 descriptionThree mb-8">Discount: <span className="float-right fw-600 color-DA2E1F">₹{isNaN(disPrice)?'0':disPrice}</span></p>
                                </div>
                                <h2 className="titleFour fw-600 text-uppercase mb-20">Total<span className="float-right">₹{isNaN(total)?'0':total}</span></h2>
                                <Link to={`/checkout/${totalItems}/${subtotal}/${disPrice}/${total}`}>
                                    <input type='button' style={{ width: '100%' }} className="btn bgPrimaryColor white" value="Check Out" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* cartsection two - end */}
            {/* footer - start */}
            <Footer />
            {/* footer - end */}
        </>
    );
}
export default Cart;