import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import Navigation from "./nav";
import Footer from "./footer";
import aboutSecTwoImg from '../assets/img/aboutSecTwo.jpg';
import aboutSecThreeImg from '../assets/img/aboutSecThree.jpg';
import logoEmb from "../assets/img/logoEmb.png";
import designSecFourImg from "../assets/img/designSecFour.png";

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);  // when page this page is call from another page its working
    }, []);
    return (
        <>
            {/* navbar - start */}
            <Navigation />
            {/* navbar - end */}
            {/* hero section - start */}
            <div className="container-fluid d-flex justify-content-center align-items-center aboutHeroSec">
                <div className="leftSideBg"></div>
                {/* <div className="rightSideBg"></div> */}
                <div className="container">
                    <div className="row row-gap">
                        <div className="col-lg-6 col-md-6 m-auto">
                            <div className="left">
                                <h1 className="titleTwo fw-600 black-232323">About <span className="secondaryColor">Us</span></h1>
                                <h3 className='titleFour fw-500 black-272727 mb-0 text-justify'>Pushing Technology Boundaries isn't our<br/> aspiration; it's our Day-To-Day</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 m-auto">
                            <div className="right">
                                <img className="aboutUsImg" src={logoEmb} alt='corechip' />
                            </div>
                            {/* <div className="right text-center br-4">
                                <p className="description fw-400 white mb-0">Corechip is recognized as one of the most respected Electronic Design & Engineering service companies in INDIA. At our core, we're a professional team of world-class engineers and designers, passionate about our craft and dedicated to quality, consistency, on-time delivery, and data protection. We serve customers across the World.</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* hero section - end */}
            {/* aboutsection one - start */}
            <div className="container-fluid aboutSecOne bgColor-F2F2F2 py-40">
                <div className="container text-justify">
                    <h1 className="titleThree fw-600 black">About <span className="primaryColor">Corechip</span></h1>
                    <p className="descriptionThree fw-400 black">Corechip was founded in 2019 as a family-run electronics manufacturing business and continues to grow, now possessing valuable assets and global capacities while still maintaining a close network with its customers.</p>
                    <p className="descriptionThree fw-400 black">With long-term global partnerships combined with manufacturing facilities in the Indian and the Far East, we’re able to offer large-scale electronics manufacturing and pricing without neglecting the foundations on which our success has grown: the personable, responsive service that you get from a privately owned company.</p>
                    <p className="descriptionThree fw-400 black">We believe our unique selling point is the outstanding level of service we provide. Our design and manufacturing capabilities are vast but we also ensure we provide expert advice, friendly communication and helpful support at all stages.</p>
                    <p className="descriptionThree fw-400 black">Corechip is recognized as one of the most respected Electronic Design & Engineering service companies in India. At Corechip, we're a professional team of world-class engineers and designers, passionate about our craft and dedicated to quality, consistency, on-time delivery, and data protection. We serve customers across the World.</p>
                </div>
            </div>
            {/* aboutsection one - end */}
            {/* aboutsection two - start */}
            <div className="container-fluid aboutSecTwo py-40">
                <div className="container">
                    <div className="row row-gap">
                        <div className="col-lg-6 col-md-8 m-auto text-justify">
                            <h1 className="titleThree fw-600 black">What <span className="secondaryColor">We Do</span></h1>
                            <p className="descriptionThree fw-400 black">However large or small your project, we will always aim to exceed your expectations. Throughout your project, there are always opportunities for you to speak with different members of our team from customer support to purchasing experts, product designers to engineers. We actively encourage business reviews; we can peer review your project, host site visits showcasing our facilities, or come to you.</p>
                            <p className="descriptionThree fw-400 black">When asked for a quote, we will collaborate closely with you to establish your needs and priorities to work out the best price and manufacturing methods for your project. Our commitment to fast lead times, cost reduction and individual customer support will ensure we exceed your expectations as project partner.</p>
                            <p className="descriptionThree fw-400 black">As a customer, you will be assigned a dedicated Account Manager who will be your main point of contact to guide you through the entire process. However, any member of the team will be happy to answer any questions or to discuss a project with you.</p>
                        </div>
                        <div className="col-lg-6 col-md-4 m-auto">
                            <img className="img-fluid" src={aboutSecTwoImg} alt='corechiptech' />
                        </div>
                    </div>
                </div>
            </div>
            {/* aboutsection two - end */}
            {/* aboutsection three - start */}
            <div className="container-fluid aboutSecThree py-40">
                <div className="container">
                    <div className="row row-gap">
                        <div className="col-lg-6 col-md-4 m-auto">
                            <img className="img-fluid" src={aboutSecThreeImg} alt='corechiptech' />
                        </div>
                        <div className="col-lg-6 col-md-8 m-auto text-justify">
                            <h1 className="titleThree fw-600 black">How <span className="primaryColor">We Do It</span></h1>
                            <p className="descriptionThree fw-400 black">Our working environment revolves around our origins of being a family-run business; relaxed but proactive. Our great track record for staff retention – with many of our employees having over 5 years of service to our company – reflects our positive work environment and pride in our staff's in-depth knowledge of the industry; a key benefit to our customers.</p>
                            <p className="descriptionThree fw-400 black">Corechip operates on a very 'hands-on' approach, with each member of staff receiving training in each department to understand the overall customer experience of the different project stages. Our highly trained, multi-disciplined workforce ensure an efficient and flexible company.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* aboutsection three - end */}
            {/* designSecFour Section - Start */}
            <div className="container-fluid aboutSecFour bgColor-F2F2F2">
                <div className="container">
                    <h1 className="titleThree fw-600 text-uppercase text-center primaryColor">OUR CORE COMPETENCIES</h1>
                    <p className="py-8 titleFour fw-500 black text-justify">System Architecture & Design, Analog, RF, Mixed-Signal & Wireless, Small Embedded to High-Speed Digital, Battery Powered Handheld & Wearable, FPGA, Firmware, Mechanical.</p>
                </div>
                {/* <div className="row row-gap">
                    <div className="col-lg-6 m-auto p-0">
                        <img className="designSecFourImg" src={designSecFourImg} alt='corechip' />
                    </div>
                    <div className="col-12 m-auto text-center">
                        <div>
                            <h1 className="titleThree m-auto fw-600 width-fit-content br-30"></h1>
                            <div className="mt-2">
                                <h4 className="py-8 titleFour fw-500 primaryColor"></h4>
                                <h4 className="py-8 titleFour fw-500 primaryColor"></h4>
                                <h4 className="py-8 titleFour fw-500 primaryColor"></h4>
                                <h4 className="py-8 titleFour fw-500 primaryColor"></h4>
                                <h4 className="py-8 titleFour fw-500 primaryColor"></h4>
                                <h4 className="py-8 titleFour fw-500 primaryColor"></h4>
                                <h4 className="py-8 titleFour fw-500 primaryColor"></h4>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            {/* designSecFour Section - end */}

            {/* designSecFive Section - start */}
            <div className="container-fluid designSecFive py-40">
                <div className="container">
                    <div className="row row-gap">
                        <div className="col-lg-6 text-justify">
                            <h1 className="titleThree fw-700 primaryColor">Experience</h1>
                            <p className="descriptionTwo fw-400 black">We have partnered in designs that have a few cost-sensitive parts to large-scale systems with multiple PCBs and thousands of components; embedded platforms that require extreme temperature resilience and sensitive medical wearables. We thrive in new technical challenges. We know the steps, and often the missteps, in bringing an idea from napkin to reality.</p>
                        </div>
                        <div className="col-lg-6 text-justify">
                            <h1 className="titleThree fw-700 primaryColor">Collaboration is Key</h1>
                            <p className="descriptionTwo fw-400 black">Engineering is a team sport. It requires many disciplines to work together. Our goal is to bring our technical know-how together with your topic experts to realize an idea. At the core of this is continual communication. We listen to your design intent, cooperatively design a plan, and work in lockstep throughout the design process.</p>
                        </div>
                        <div className="col-lg-6 text-justify">
                            <h1 className="titleThree fw-700 primaryColor">Phased Approach</h1>
                            <p className="descriptionTwo fw-400 black">We divide the development process into key phases. Each step refines both design direction and intent while aiming to eliminate extensive backtracks or design redundancies. The final manufacturable product is always our North Star. Not all projects require all phases. Let's discuss how we can best serve you.</p>
                        </div>
                        <div className="col-lg-6 text-justify">
                            <h1 className="titleThree fw-700 primaryColor">Tools & Libraries</h1>
                            <p className="descriptionTwo fw-400 black">We are power users of all major EDA tools. We can create any necessary components either in your ecosystem or through Corechip’s Virtual Librarian creation service. Starting from scratch? We can help there too.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* designSecFive Section - end */}

            {/* footer - start */}
            <Footer />
            {/* footer - end */}
        </>
    )
}
export default AboutUs;