import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import Navigation from "./nav";
import Footer from "./footer";
import serviceSecOneImg from "../assets/img/serviceSecOneImg.svg";
import logoEmb from "../assets/img/logoEmb.png";
import serviceOne from "../assets/img/designServiceOne.jpg";
import serviceTwo from "../assets/img/designServiceTwo.jpg";
import serviceThree from "../assets/img/designServiceThree.jpg";
import serviceFour from "../assets/img/designServiceFour.jpg";
import serviceFive from "../assets/img/designServiceFive.jpg";
import serviceSix from "../assets/img/designServiceSix.jpg";
import serviceSeven from "../assets/img/designServiceSeven.jpg";
import serviceEight from "../assets/img/designServiceEight.jpg";
import serviceNine from "../assets/img/designServiceNine.jpg";
import serviceTen from "../assets/img/designServiceTen.jpg";
import serviceEleven from "../assets/img/designServiceEleven.jpg";
import serviceTwelve from "../assets/img/designService12.jpg";
import designGuide from "../assets/pdf/OutsourcingElectronicsDesignGuide.pdf";
import { Link } from "react-router-dom";
const ElectronicDesign = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // when page this page is call from another page its working        
    }, []);
    return (
        <>
            {/* navbar - start */}
            <Navigation />
            {/* navbar - end */}
            {/* hero section - start */}
            <div className="container-fluid serviceSec d-flex justify-content-center align-items-center">
                {/* <img className="serviceSecOneImg" src={serviceSecOneImg} alt='corechip' /> */}
                <div className="leftSideBg"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-12 m-auto">
                            <div className="">
                                <h1 className="titleThree fw-600 black-232323">Corechip Design</h1>
                                <p className="descriptionTwo fw-400 black-272727 mb-0">Extensive electronic system and hardware engineering experience</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 m-auto">
                            <div className="right">
                                <img className="aboutUsImg" src={logoEmb} alt='corechip' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* hero section - end */}
            {/* designSecTwo section - start */}
            <div className="container-fluid designSecTwo py-40">
                <div className="container">
                    <div className="row row-gap">
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/design/v-pills-link1">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceOne} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Design Process</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to='/design/v-pills-link2'>
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceTwo} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Electronic Design</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/design/v-pills-link3">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceThree} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">PCB Layout</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/design/v-pills-link4">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceFour} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Firmware Development</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/design/v-pills-link5">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceFive} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Software Development</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/design/v-pills-link6">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceSix} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Product Design</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/design/v-pills-link7">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceSeven} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">3D CAD</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/design/v-pills-link8">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceEight} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Prototyping</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/design/v-pills-link9">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceNine} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Design For Manufacture</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/design/v-pills-link10">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceTen} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Product Compliance</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/design/v-pills-link11">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceEleven} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Display Technology</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <Link to="/design/v-pills-link12">
                                <div className="card b-none">
                                    <div className="overlay h-100 w-100" ></div>
                                    <img className="img-fluid" src={serviceTwelve} alt="corechip" />
                                    <h1 className="titleFour text-center fw-600">Wireless IoT</h1>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* designSecTwo section - end */}
            {/* designSecThree Section - start */}
            <div className="container-fluid designSecThree bgColor-F2F2F2 py-40">
                <div className="container text-justify">
                    <h1 className="titleThree fw-500 black">Electronics Design</h1>
                    <h2 className="titleFour fw-600 black">Why Choose Corechip?</h2>
                    <p className="descriptionTwo fw-400 black">Using an experienced and proven design and manufacturing specialist can offer your product development the following benefits:</p>
                    <ul>
                        <li className="descriptionTwo fw-400 black">Increased technical resource, equipment, staff knowledge and experience.</li>
                        <li className="descriptionTwo fw-400 black">A broad range of engineering expertise, mechanical, electronics (PCB design, software/firmware development, wireless & IoT, and more), design for test, design for manufacture, and much more.</li>
                        <li className="descriptionTwo fw-400 black">Design optimised for manufacturability, lower risk of production issues (e.g. component obsolescence), improved quality, reduced costs.</li>
                        <li className="descriptionTwo fw-400 black">One point of contact, rather than coordinating a mix of in-house resources and external freelancers/design consultancies.</li>
                        <li className="descriptionTwo fw-400 black">Fast-turnaround prototype batches.</li>
                        <li className="descriptionTwo fw-400 black">Easy transition to full-scale production.</li>
                    </ul>
                    <p className="descriptionTwo fw-400 black">Corechip has over five years' experience developing electronic products for customers in a variety of different industry sectors. We believe we are perfectly placed to deliver these benefits and more for your business.</p>
                    {/* <a href={designGuide} target='_blank' rel="noopener noreferrer"><input type="button" className="btn text-uppercase bgPrimaryColor fw-600 white br-20" value="Outsourcing Electronics Design Guide" /></a> */}
                    <p className="descriptionTwo fw-400 black mt-10">We aim to tailor our service for every customer's unique requirements, contact our team to discuss yours requirement today !</p>
                </div>
            </div>
            {/* designSecThree Section - end */}

            {/* footer - start */}
            <Footer />
            {/* footer - end */}
        </>
    )
}
export default ElectronicDesign;