import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import locationIcon from '../assets/img/locationIconGreen.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
    const handleCallClick = () => {
        window.location.href = `tel: +917867988679`;
    }
    return (
        <>
            {/* Footer Section - Start */}
            <div className='container-fluid footer bgPrimaryColor pt-50'>                
                {/* <div className='container footerFloating'>
                    <div className='card bgSecondaryColor b-none'>
                        <div className='row row-gap'>
                            <div className='col-lg-9 m-auto'>
                                <p className='titleThree primaryColor fw-500 mb-0'>Looking for Quality Electronic Components ?</p>
                            </div>
                            <div className='col-lg-3 text-center m-auto'>
                                <input onClick={handleCallClick} type="button" value="Contact Us" className="btn bgPrimaryColor text-uppercase br-2 white fw-600" />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='container first'>
                    <div className='row row-gap'>
                        <div className='col-lg-6 col-md-5 col-sm-5'>
                            <div className=''>
                                <h1 className='titleFour fw-600 secondaryColor mb-20'>Explore Our company</h1>
                                <div className='inner'>
                                    <Link to="/"><p className="descriptionThree white fw-400 mb-1">Home</p></Link>
                                    <Link to="/aboutus"><p className="descriptionThree white fw-400 mb-1">About us</p></Link>
                                    <Link to="/electronicsdesign"><p className="descriptionThree white fw-400 mb-1">Design</p></Link>
                                    <Link to="/electronicsmanufacturing"><p className="descriptionThree white fw-400 mb-1">Manufacture</p></Link>
                                    <Link to="/standardproducts"><p className="descriptionThree white fw-400 mb-1">Products</p></Link>
                                    <Link to="/contactus"><p className="descriptionThree white fw-400 mb-1">Contact us</p></Link>
                                </div>
                            </div>
                        </div>                        
                        <div className='col-lg-6 col-md-7 col-sm-7'>
                            <div className='inner center'>
                                <h1 className='titleFour fw-600 secondaryColor mb-20'>Office Address</h1>
                                <div className='d-flex align-items-center'>
                                    <div className='iconBox bgColor-5D7D86'>
                                        <img className='footerIcon' src={locationIcon} alt='corechip' />
                                    </div>
                                    <div className='contentBox'>
                                        <p className="descriptionThree white fw-400 mb-0">Module # S/2, 1st Floor, TIDEL Park,<br/> ELCOSEZ, Civil Aerodrome PO,<br/> Coimbatore - 641014, India.</p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center mt-2'>
                                    <div className='iconBox bgColor-5D7D86'>
                                        <img className='footerIcon' src={locationIcon} alt='corechip' />
                                    </div>
                                    <div className='contentBox'>
                                        <p className="descriptionThree white fw-400 mb-0">SF No. 731, Naranapuram Village, Aalampalayam Pirivu,<br/>Thennampalayam to Annur Road,<br/>Coimbatore - 641201, India.</p>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid bgPrimaryColor footerBottom pb-20'>
                <div className='container'>
                    <div className='d-flex justify-content-between flex-column align-items-center'>
                        <p className='descriptionThree fw-400 text-white mb-1 text-center'>Copyright @ Corechip Technologies Pvt. Ltd. - All rights reserved</p>                        
                    </div>
                </div>
            </div>
            {/* Footer Section - End */}
        </>
    )
}
export default Footer