import React, { useState,useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Footer from "./footer";
import Navigation from "./nav";

const ProductDetails = () => {
    const {id, pname} = useParams();
    const navigate = useNavigate();
    const [details, setDetails] = useState({name: pname});
    const [selectedImage, setSelectedImage] = useState([]);
    const [select, setSelect] = useState({size:"4 Feet", color:"Black"})
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState({})
    const [isButtonDisabled, setIsButtonDisabled] = useState();
    const [image, setImage] = useState();
    const [boo, setBoo] = useState(false);

    useEffect(() => {
        const getDetailsById = async () => {
            try{
                const response = await axios.post(`https://us-central1-corechipadminbackend.cloudfunctions.net/api/product/get_productbyId`, {productId: id});
                const { productName, description, productImage, size, color} = await response.data.data;
                setDetails({
                    ...details,
                    name: productName,
                    desc: description,
                    img: productImage,
                    size: size,
                    color: color
                })
            } catch {
                alert("Server Error");
            }
        }  
        getDetailsById();
        setBoo(true);
    },[]);

    useEffect(() => {
        if(boo){
            setSelectedImage([...details.img]);
            setImage(selectedImage[0]);
        }             
    }, [details]);

    useEffect(() => {
        getDetailsBySC();
    }, [select]);

    const getDetailsBySC =async () => {
        try {
            const response = await axios.post(`https://us-central1-corechipadminbackend.cloudfunctions.net/api/price/getPrice_byid`, {id: id, ...select});
            const {priceAmount, priceDiscount} = response.data.data[0];
            if(priceAmount){
                setPrice({amount: priceAmount, discount: priceDiscount});
                setIsButtonDisabled(false);
            }else{
                setIsButtonDisabled(true);
                setPrice({});
                alert("Stock Unavailable");
            }
        } catch {
            alert("Server Error");
        }
    };
   
    const handleChange = (event) => {
        const {name, value} = event.target;
        setSelect({...select, [name]:value});
    };

    const handleIncrement = () => {
        setQuantity(quantity + 1);
    };

    const handleDecrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const handleAddToCart = () => {
        const item = {
            name: details.name,
            price: price.amount.replace(/,/g,""),
            discount: price.discount.replace(/,/g,""),
            imageUrl: image,
            size: select.size,
            color: select.color,
            quantity: quantity,
        };
        // Pass item data as route parameters to the Cart page
        navigate(`/cart?name=${encodeURIComponent(item.name)}&price=${encodeURIComponent(item.price)}&size=${encodeURIComponent(item.size)}&color=${encodeURIComponent(item.color)}&quantity=${encodeURIComponent(item.quantity)}&discount=${encodeURIComponent(item.discount)}&imgUrl=${encodeURIComponent(item.imageUrl)}`);
    };

    return (
        <>
            {/* navbar - start */}
            <Navigation />
            {/* navbar - end */}
            {/* product details section one - start */}
            <div className="container-fluid prodDetSecOne bgPrimaryColor d-flex justify-content-center align-items-center">
                <div className="container">
                    <h1 className="text-uppercase fw-600 titleTwo text-center white">Product Details</h1>
                </div>
            </div>
            {/* product details section one - end */}
            {/* product details section two - start */}
            <div className="container-fluid prodDetSecTwo py-50">
                <div className="container">
                    <div className="row row-gap">
                        <div className="col-lg-6 col-md-6">
                            <div className="card"> 
                                <p className="sale text-uppercase headerText fw-500 white bgColor-DA2E1F br-4">sale</p>
                                <div className="d-flex align-items-center" style={{ height: '100%' }} >
                                    <div style={{ width: '20%' }}>
                                        {selectedImage.map((img, index)=>(
                                            <div className="img-list" key={index} onClick={() => setImage(img)}>
                                                <img src={img} alt='corechip' />
                                            </div>
                                        ))}                                        
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <img className="productImg" src={image} alt='corechip' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="inner">
                                <h1 className="titleThree fw-600 black-232323">{details.name}</h1>
                                <div className="d-flex align-items-center">
                                <p className="titleThree fw-500 color-DA2E1F"><span>&#8377;</span> {price.discount}</p>
                                <p className="disPrice text-decoration-line-through mb-0 ml-20"><span>&#8377;</span> {price.amount}</p>
                                </div>
                                <ul>
                                    <li className="fw-400 descriptionTwo color-454444">User Friendly</li>
                                    <li className="fw-400 descriptionTwo color-454444">35 Kgs Capacity</li>
                                    <li className="fw-400 descriptionTwo color-454444">Different Lengths</li>
                                    <li className="fw-400 descriptionTwo color-454444">Power Consumption In Negligible</li>
                                    <li className="fw-400 descriptionTwo color-454444">Zero Maintenance</li>
                                    <li className="fw-400 descriptionTwo color-454444">1 Year Warranty</li>
                                </ul>
                                <div className="d-flex justify-content-between size">
                                    <div className="d-flex flex-column control mr-10">
                                        <h1 className="fw-400 descriptionThree color-7F7878">Size</h1>
                                        <div className="size-dropdown">
                                            <select value={select.size} onChange={handleChange} name="size">
                                                <option value="4 Feet">4 Feet</option>
                                                <option value="5 Feet">5 Feet</option>
                                                <option value="6 Feet">6 Feet</option>
                                                <option value="7 Feet">7 Feet</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column control ml-10">
                                        <h1 className="fw-400 descriptionThree color-7F7878">Color</h1>
                                        <div className="color-dropdown">
                                            <select value={select.color} onChange={handleChange} name="color">
                                                <option value="Black">Black</option>
                                                <option value="Brown">Brown</option>
                                                <option value="Gray">Gray</option>
                                                <option value="Silver">Silver</option>                                                
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {/* Other content */}
                                <div className="d-flex justify-content-between mt-30 quantity">
                                    <div className="quantity-controls br-6">
                                        <button className="control-btn" onClick={handleDecrement}>-</button>
                                        <span className="quantity-value">{quantity}</span>
                                        <button className="control-btn" onClick={handleIncrement}>+</button>
                                    </div>
                                    <div className="add-cart mt-auto mb-auto">
                                        <button className="br-4 fw-500 descriptionOne text-uppercase primaryColor" onClick={handleAddToCart} disabled={isButtonDisabled}>
                                            Add to Cart
                                        </button>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between mt-30 buynow-control">
                                    <button className="text-uppercase bgPrimaryColor white b-none br-4 description fw-500" onClick={handleAddToCart} disabled={isButtonDisabled}>buy now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* product details section two - start */}
            {/* footer - start */}
            <Footer />
            {/* footer - end */}
        </>
    )
}
export default ProductDetails;