import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Navbar, Nav, Form, NavDropdown } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import Toggler from "../toggler";
import logo from '../assets/img/logo.png';
import phone from '../assets/img/mobileIconWhite.svg';
import email from '../assets/img/emailIconWhite.svg';
import profile from '../assets/img/profile.png';


const Navigation = ({ totalItems }) => {
    const toggle = localStorage.getItem("authToken");
    const userName = localStorage.getItem("username");
    const [show, setShow] = useState(true);
    useEffect(() => {
        if (toggle === 'false') {
            setShow(false);
        }
    }, [])

    const [showDesignDropdown, setShowDesignDropdown] = useState(false);
    const [showManufactureDropdown, setShowManufactureDropdown] = useState(false);
    const handleDesignDropdown = () => {
        setShowDesignDropdown(!showDesignDropdown);
    };
    const handleManufactureDropdown = () => {
        setShowManufactureDropdown(!showManufactureDropdown);
    };

    const [showNavbar, setShowNavbar] = useState(false);
    const handleNavbarToggle = () => {
        setShowNavbar(!showNavbar);
    };

    const logout = () => {
        alert("User Logged Out !");
        localStorage.setItem("authToken", "true");
        localStorage.setItem("username", "");
        localStorage.setItem("products", []);
        setShow(true);
        window.location.reload();
    }

    return (
        <>
            {/* header - start */}
            <div className="container-fluid header bgPrimaryColor py-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 m-auto">
                            <Link to='/contactus'><input type="button" value="contactus" className="btn btnHover2 text-uppercase br-2 white fw-600" /></Link>
                        </div>
                        <div className="col-lg-3 m-auto" >
                            {/* <div className="icon d-flex justify-content-end">
                                <img src={email} alt='corechip' />
                                <div class="ml-3">
                                    <a href="mailto:corechiptechcbe@gmail.com"><p className="m-0 descriptionThree white fw-500">corechiptechcbe@gmail.com</p></a>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-lg-3 m-auto" >
                            {/* <div className="icon d-flex justify-content-end">
                                <img src={phone} alt='corechip' />
                                <div class="ml-3">
                                    <a href='tel:+917867988679'><p className="descriptionThree white fw-500 m-0">78679 88679</p></a>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-lg-3 m-auto d-flex justify-content-end flex-column" >                                                        
                            <div className="icon d-flex justify-content-end">
                                <img src={phone} alt='corechip' />
                                <div class="ml-3">
                                    <a href='tel:+917867988679'><p className="descriptionThree white fw-500 m-0">78679 88679</p></a>
                                </div>
                            </div>     
                            <div className="icon d-flex justify-content-end">
                                <img src={email} alt='corechip' />
                                <div class="ml-3">
                                    <a href="mailto:info@corechiptech.com"><p className="m-0 descriptionThree white fw-500">info@corechiptech.com</p></a>
                                </div>
                            </div>                       
                        </div>
                    </div>
                </div>
            </div>
            {/* header - end */}
            {/* navbar - start */}
            <div className="container-fluid" style={{ boxShadow: '0 4px 2px -2px rgba(0,0,0,.2)' }}>
                <Navbar className="container" expand="lg">
                    <Navbar.Brand href="/">
                        <img src={logo} alt="corintech" />
                    </Navbar.Brand>
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                    {/* <Navbar.Toggle onClick={handleNavbarToggle} aria-controls="basic-navbar-nav" className="custom-toggler" /> */}
                    <Toggler onClick={handleNavbarToggle} isActive={showNavbar} />

                    <Navbar.Collapse id="basic-navbar-nav" className={showNavbar ? 'show' : ''}>
                        <Nav className="">
                            <NavLink exact className="nav-link black-1B1919 fw-600 text-capitalize" to="/" activeClassName="active">
                                Home
                            </NavLink>
                            <NavLink className="nav-link black-1B1919 text-capitalize" to="/aboutus" activeClassName="active">
                                About Us
                            </NavLink>
                            <NavLink className="nav-link d-flex black-1B1919 text-capitalize pos-relative" to="/electronicsdesign" activeClassName="active">
                                Design
                                <NavDropdown className="desProd text-capitalize pos-absolute" id="basic-nav-dropdown"
                                    show={showDesignDropdown}
                                    onMouseEnter={() => setShowDesignDropdown(true)}
                                    onMouseLeave={() => setShowDesignDropdown(false)}
                                    onClick={handleDesignDropdown}
                                >
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/design/v-pills-link1" activeClassName="active">
                                            Product Development Process
                                        </NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/design/v-pills-link2" activeClassName="active">
                                            Electronic Design
                                        </NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/design/v-pills-link3" activeClassName="active">
                                            PCB Layout
                                        </NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/design/v-pills-link4" activeClassName="active">
                                            Firmware Development
                                        </NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/design/v-pills-link5" activeClassName="active">
                                            Software Development
                                        </NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/design/v-pills-link6" activeClassName="active">
                                            Product Design
                                        </NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/design/v-pills-link7" activeClassName="active">
                                            3D CAD
                                        </NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/design/v-pills-link8" activeClassName="active">
                                            Prototyping
                                        </NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/design/v-pills-link9" activeClassName="active">
                                            Design & Manufacture
                                        </NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/design/v-pills-link10" activeClassName="active">
                                            Product Compliance
                                        </NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/design/v-pills-link11" activeClassName="active">
                                            Wireless & IoT
                                        </NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/design/v-pills-link12" activeClassName="active">
                                            Display Technology
                                        </NavLink>
                                    </NavDropdown.Item>
                                    {/* Add more NavDropdown.Items for other services */}
                                </NavDropdown>
                            </NavLink>
                            <NavLink className="nav-link d-flex black-1B1919 text-capitalize pos-relative" to="/electronicsmanufacturing" activeClassName="active">
                                Manufacture
                                <NavDropdown className="desProd text-capitalize pos-absolute" id="basic-nav-dropdown"
                                    show={showManufactureDropdown}
                                    onMouseEnter={() => setShowManufactureDropdown(true)}
                                    onMouseLeave={() => setShowManufactureDropdown(false)}
                                    onClick={handleManufactureDropdown}
                                >
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/manufacture/v-pills-link-m1" activeClassName="active">
                                            Component Sourcing
                                        </NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/manufacture/v-pills-link-m2" activeClassName="active">
                                            PCB Assembly
                                        </NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/manufacture/v-pills-link-m3" activeClassName="active">
                                            Box Build
                                        </NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/manufacture/v-pills-link-m4" activeClassName="active">
                                            Flexible Supply
                                        </NavLink>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/manufacture/v-pills-link-m5" activeClassName="active">
                                            Wire Bonding
                                        </NavLink>
                                    </NavDropdown.Item>
                                    {/* <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/manufacture/v-pills-link-m6" activeClassName="active">
                                            Thick Film Hybrids
                                        </NavLink>
                                    </NavDropdown.Item> */}
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/manufacture/v-pills-link-m7" activeClassName="active">
                                            Laser Trimming
                                        </NavLink>
                                    </NavDropdown.Item>
                                    {/* <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/manufacture/v-pills-link-m8" activeClassName="active">
                                            Coating and Encapsulation
                                        </NavLink>
                                    </NavDropdown.Item> */}
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/manufacture/v-pills-link-m9" activeClassName="active">
                                            Production Engineering
                                        </NavLink>
                                    </NavDropdown.Item>
                                    {/* <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/manufacture/v-pills-link-m10" activeClassName="active">
                                            Quality Accreditations
                                        </NavLink>
                                    </NavDropdown.Item> */}
                                    <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/manufacture/v-pills-link-m11" activeClassName="active">
                                            Indian Electronics Assembly
                                        </NavLink>
                                    </NavDropdown.Item>
                                    {/* <NavDropdown.Item>
                                        <NavLink className="nav-link black-1B1919 text-capitalize" to="/manufacture/v-pills-link-m12" activeClassName="active">
                                            Far East Manufacturing
                                        </NavLink>
                                    </NavDropdown.Item> */}
                                    {/* Add more NavDropdown.Items for other services */}
                                </NavDropdown>
                            </NavLink>
                            <NavLink className="nav-link black-1B1919 text-capitalize" to="/standardproducts" activeClassName="active">
                                Products
                            </NavLink>
                            <NavLink className="nav-link black-1B1919 text-capitalize" to="/contactus" activeClassName="active">
                                Contact Us
                            </NavLink>
                        </Nav>
                        {/* <Form inline>
                            {show && 
                                <div>
                                    <NavLink className="btn fw-500 text-capitalize br-6 white bgPrimaryColor" to="/signin">Sign In</NavLink>
                                    <NavLink className="btn fw-500 text-capitalize br-6 primaryColor bgWhite" to="/signup">Sign Up</NavLink>
                                </div>}
                            {!show &&
                                <div className="d-flex">
                                    <div className="d-flex align-items-center mr-10">
                                        <img src={profile} alt="profile" style={{width:'30px'}} /> 
                                        <p className="mb-0">{userName}</p>
                                    </div>
                                    <NavLink className="btn fw-500 text-capitalize br-6 primaryColor bgWhite" onClick={logout}>Logout</NavLink>
                                </div>
                            }                            
                        </Form> */}
                    </Navbar.Collapse>
                </Navbar>
            </div>
            {/* navbar - end */}
        </>
    );
};
export default Navigation;