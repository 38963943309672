import React from 'react';

const Toggler = ({ onClick, isActive }) => {
  return (
    <div className={`toggleMenu ${isActive ? 'active' : ''}`} onClick={onClick}>
      <span></span>
    </div>
  );
};

export default Toggler;