import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navigation from './nav';
import waveOne from '../assets/img/waveOne.svg';
import dots from '../assets/img/dots.svg';
import Slider from "react-slick";
import Footer from './footer';
import { Link } from 'react-router-dom';
import design from '../assets/img/design.jpg';
import manufacture from '../assets/img/manufacture.jpg';
import product from '../assets/img/product.jpg';
import axios from 'axios';
import logoEmb from "../assets/img/logoEmb.png";
import homeSecFourImg from "../assets/img/homeSecFourImg.png";
import homeSecFiveImg from "../assets/img/homeSecFiveImg.png";
import homeSecSixImg from "../assets/img/homeSecSixImg.png";

const Index = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        localStorage.setItem("products", JSON.stringify([]));
        window.scrollTo(0, 0);
        axios.post('https://us-central1-corechipadminbackend.cloudfunctions.net/api/banner/get_banner', { /* your data for the POST request */ })
            .then(response => {
                setData(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const sliderSettings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        autoplaySpeed: 3000
    }
    return (
        <>
            {/* navigation start */}
            <Navigation />
            {/* navigation end */}
            {/* hero Section - start */}
            <div className='container-fluid homeSecOne px-0 ' >
                <div className='position-absolute w-100 h-auto bgBlack-opacity'></div>
                <Slider {...sliderSettings}>
                    {data.map((items, index) => (
                        <div key={index}
                            className={`item text-center d-flex flex-column justify-content-center align-items-center`}>
                            <img className='bannerImage' src={items.bannerImage} alt='bannerImage' />
                            <div className="card d-flex flex-column justify-content-center align-items-center p-10 b-none bgBlack-opacity h-100 w-100">
                                <h1 className="titleThree mt-15 fw-600 white text-center text-uppercase">{items.bannerTitle}</h1>
                                <p className="description mt-15 fw-400 white text-center">{items.bannerDesc}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            {/* hero Section - end */}
            {/* cuting edge tech - start */}
            <div className='container-fluid py-40 px-0 homeSecThree'>
                <img className='waveOne' src={waveOne} alt='corechip' />
                <img className='dots' src={dots} alt='corechip' />
                <div className='container'>
                    <div className='row mt-20 row-gap'>
                        <div className='col-lg-4 col-md-4 left'>
                            <div className='card br-10 d-flex justify-content-evenly'>
                                <img className='img-fluid' src={design} alt='corechip' />
                                <div className='card-body text-center'>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <img className='logoEmb' src={logoEmb} alt='corechip' />
                                        <h1 className='titleFour fw-600 primaryColor mb-0 ml-15'>Corechip Design</h1>
                                    </div>                                    
                                    <p className='descriptionThree fw-400 black-444444 mt-10 text-justify'>Our experienced electronics engineers, product designers and production engineers can accelerate your initial concept to a production-ready design.</p>
                                    <Link to='/electronicsdesign'><button className='btn btnHover bgSecondaryColor br-20 fw-500 descriptionTwo'>Design Service</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 center'>
                            <div className='card br-10 d-flex justify-content-evenly'>
                                <img className='img-fluid' src={manufacture} alt='corechip' />
                                <div className='card-body text-center'>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <img className='logoEmb' src={logoEmb} alt='corechip' />
                                        <h1 className='titleFour fw-600 primaryColor mb-0 ml-15'>Corechip Manufacture</h1>
                                    </div>
                                    <p className='descriptionThree fw-400 black-444444 mt-10 text-justify'>We offer full-service manufacturing and supply. From component sourcing to PCB assembly and full box-build in the Indian and the Far East.</p>
                                    <Link to='/electronicsmanufacturing'><button className='btn btnHover bgSecondaryColor br-20 fw-500 descriptionTwo'>Manufacturing Service</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 right'>
                            <div className='card br-10 d-flex justify-content-evenly'>
                                <img className='img-fluid' src={product} alt='corechip' />
                                <div className='card-body text-center'>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <img className='logoEmb' src={logoEmb} alt='corechip' />
                                        <h1 className='titleFour fw-600 primaryColor mb-0 ml-15'>Corechip Product</h1>
                                    </div>
                                    <p className='descriptionThree fw-400 black-444444 mt-10 text-justify'>Our range of wireless sensors and controls, designed and manufactured at Corechip, is sold through our authorised distributors world wide.</p>
                                    <Link to='/standardproducts'><button className='btn btnHover bgSecondaryColor br-20 fw-500 descriptionTwo '>Our Products</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* cuting edge tech - end */}
            {/* elite experience - start */}
            <div className='container-fluid py-40 px-0 homeSecFour'>
                <div className='container'>
                    <div className='row row-gap'>
                        <div className='col-lg-7 text-justify'>
                            <h1 className='fw-500 titleTwo mb-0 primaryColor'>Elite Experience</h1>
                            <p className='descriptionTwo fw-400 black-272727'>In the world of electronic circuit board design and engineering solutions, no one does it better than us. Our proven methodologies and vast experience focus on quality and results. We understand today’s technology and tools, and our professional team of engineers and designers have what it takes to execute.</p>
                        </div>
                        <div className='col-lg-5'>
                            <div>
                                <img className='homeSecFourImg' src={homeSecFourImg} alt='corechip'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* elite experience - end */}
            {/* true partnership - start */}
            <div className='container-fluid py-40 px-0 homeSecFive bgColor-F2F2F2'>
                <div className='container'>
                    <div className='row row-gap'>
                        <div className='col-lg-4 m-auto'>
                            <img className='homeSecFiveImg' src={homeSecFiveImg} alt='corechip'/>
                        </div>
                        <div className='col-lg-8 text-justify'>
                            <h1 className='fw-500 titleTwo mb-0 primaryColor'>True Partnership</h1>
                            <p className='descriptionTwo fw-400 black-272727'>We don’t take the word partnership lightly. Our culture and process dictate a constant cycle of communication throughout the design process. We listen, share our experience and work collaboratively with you through the sometimes changing requirements of a project. As a service-oriented company, we never miss an opportunity to do what it takes to meet your needs. Our open-door policy is one of proactive communication.</p> 
                            <p className='descriptionTwo fw-400 black-272727'>We know choosing a new engineering partner can feel like a risky undertaking. We assure you we understand what it takes to succeed. Our customers range from start-ups to multi-billion dollar giants. What they have in common is a need for high-level process sophistication that meets their design and data protection requirements, a desire for high-quality work delivered on time, and, most importantly, a high level of attention and flexibility. Our bottom line: when you succeed, we succeed.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* true partnership - end */}
            {/* homeSecSix - start */}
            <div className='container-fluid py-40 px-0 homeSecSix'>
                <div className='container'>
                    <div className='row row-gap'>
                        <div className='col-lg-7 m-auto text-justify'>
                            <h1 className='fw-500 titleTwo mb-0 primaryColor'>Care & Commitment</h1>
                            <p className='descriptionTwo fw-400 black-272727'>Our dedication to our clients is simple; we care a lot. From deadlines and budgets to IP protection, attention to detail, and delivery, we are laser-focused on customer satisfaction. We are not in it for the one-off project or single PCB design; we are always striving to have a customer for life. We take pride in our workmanship. We are always 100% in.</p>
                        </div>
                        <div className='col-lg-5'>
                            <img className='homeSecSixImg' src={homeSecSixImg} alt='corechip'/>
                        </div>
                    </div>
                </div>
            </div>
            {/* homeSecSix - end */}
            {/* aboutsection four - start */}
            <div className="container-fluid aboutSecFour py-40 bgColor-F2F2F2">
                <div className="container">
                    <h1 className="titleThree fw-600 text-uppercase text-center primaryColor">OUR CUSTOMER'S DEMANDS ARE <span className="secondaryColor">OUR DEMANDS</span>.<br/> OUR CUSTOMER'S SUCCESS IS <span className="secondaryColor">OUR SUCCESS</span>.</h1>
                </div>
                <div className="container text-center mt-40">
                    <div className="row">
                        <div className="col-lg-4 text-justify">
                            <h3 className="titleFour fw-700 primaryColor">We are Passionate</h3>
                            <p className="descriptionTwo fw-400 black">We absolutely love what we do. And we wouldn’t be doing anything else.</p>
                        </div>
                        <div className="col-lg-4 text-justify">
                            <h3 className="titleFour fw-700 primaryColor">We are Professional</h3>
                            <p className="descriptionTwo fw-400 black">We hire and retain the best designers and engineers, support them with whatever they need to be successful, and empower them to do their best work.</p>
                        </div>
                        <div className="col-lg-4 text-justify">
                            <h3 className="titleFour fw-700 primaryColor">We are Proactive</h3>
                            <p className="descriptionTwo fw-400 black">We strongly believe that effective communication is integral to the success of product realization. This is why we assign a Project Manager to every project.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* aboutsection four - end */}
            {/* Our Effective Process - Start */}
            {/* <div className='container-fluid homeSecEight py-40 px-0'>
                <div className='rectBox bgPrimaryColor'>
                    <h1 className='text-uppercase fw-500 white titleTwo text-center mb-0'>OUR PROCESS</h1>
                </div>
                <div className='container'>
                    <div className='row row-gap'>
                        <div className='col-lg-6 col-md-5'>
                            <div className='left mt-40'>
                                <h1 className='titleThree fw-600 primaryColor'>Our Effective</h1>
                                <h1 className='titleThree fw-600 primaryColor'>Process</h1>
                                <p className='description fw-400 black-272727'>Business isn’t one size fits all. Every industry requires a custom solution.</p>
                                <Link to='/contactus'><input type="button" value="Contact Us" className="btn btnHover2 bgPrimaryColor text-uppercase br-2 white fw-600" /></Link>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-7'>
                            <div className='right'>
                                <div className='mb-30 d-flex align-items-start'>
                                    <div className='circle dottedLine bgPrimaryColor color-F4FCFA fw-500 titleFour' style={{ letterSpacing: '3px' }}>01</div>
                                    <div className='card bgColor-F2F2F2 b-none'>
                                        <h1 className='titleFour fw-500 black'>Discover</h1>
                                        <p className='descriptionTwo fw-400 black mb-0'>Learn, Explore, Analyze</p>
                                    </div>
                                </div>
                                <div className='mb-30 d-flex align-items-start'>
                                    <div className='circle dottedLine bgPrimaryColor color-F4FCFA fw-500 titleFour'>02</div>
                                    <div className='card bgColor-F2F2F2 b-none'>
                                        <h1 className='titleFour fw-500 black'>Design</h1>
                                        <p className='descriptionTwo fw-400 black mb-0'>Ideate, Prototype, Test</p>
                                    </div>
                                </div>
                                <div className='mb-30 d-flex align-items-start'>
                                    <div className='circle dottedLine bgPrimaryColor color-F4FCFA fw-500 titleFour'>03</div>
                                    <div className='card bgColor-F2F2F2 b-none'>
                                        <h1 className='titleFour fw-500 black'>Develop</h1>
                                        <p className='descriptionTwo fw-400 black mb-0'>Build, Validate, Pilot</p>
                                    </div>
                                </div>
                                <div className='mb-30 d-flex align-items-start'>
                                    <div className='circle bgPrimaryColor color-F4FCFA fw-500 titleFour'>04</div>
                                    <div className='card bgColor-F2F2F2 b-none'>
                                        <h1 className='titleFour fw-500 black'>Deliver</h1>
                                        <p className='descriptionTwo fw-400 black mb-0'>Launch, Maintain, Measure</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Our Effective Process - End */}
            {/* Footer Section - Start */}
            <Footer />
            {/* Footer Section - End */}
        </>
    )
}
export default Index